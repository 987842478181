@import "../../constants.module.scss";

.applicationBarContainer {
  flex-grow: 0;
}
.applicationBarContainerWithContent {
    height: 100vh;
}

header.applicationHeaderContainer {
    padding: 0px;
    height: auto;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    z-index: 100;
    line-height: inherit;
    position: relative;
}

.applicationHeader {
  background: $winter-snow;
  height: 50px;
  padding: {
    left: 30px;
    right: 15px;
  };

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.organization {
  color: $faded-grey;
  line-height: 1;
}

.breadcrumb {
  &:global(.ant-breadcrumb) {
    font-size: 20px;
    line-height: 1;

    &, * {
      display: flex;
      align-items: center;

      .breadcrumbLink {
        max-width: 400px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        line-height: normal;
      }
    }
  }

  &Home {
    width: 24px;
    margin-bottom: 4px;
  }

  :global(.ant-breadcrumb-separator) {
    color: $faded-grey;
  }
}

.profile {
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  &Identification {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 10px;
  }
}

:global(#logout) {
  padding: 0;
  color: inherit;
  height: inherit;
}

.applicationBar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $winter;
  background: $snow-blind;

  .steps {
    :global(.ant-steps-item-process .ant-steps-item-icon) > :global(.ant-steps-icon) {
      color: $plain-white;
    }
  }
  .steps.limitedWidth {
    @media screen and (min-width: $small-desktop-width) {
        width: 440px;
    }
  }

  .tabsOrSteps, .actions {
    height: 37px;
    display: flex;
    align-items: center;
  }

  .tabsOrSteps {
    width: 100%;

    > :global(.ant-steps) {
      padding: {
        left: 20px;
        right: 20px;
      };

      @media screen and (max-width: $phone-width) {
        display: none;
      }
    }
  }

  &.stepsOnly {
    @media screen and (max-width: $phone-width) {
      display: none;
    }
  }

  .actions + .tabsOrSteps {
    width: 50%;
  }

  .actions {
    width: 50%;

    @media screen and (max-width: $phone-width) {
      width: 70px;
    }
  }

  :global(.ant-menu) {
    background: transparent;
  }

}

:global(.ant-btn).logInButton {
  width: 100%;
  text-align: right;
  padding: 0;
  height: auto;
}

:global(.ant-btn).menuButton {
  width: 100%;
  text-align: left;
}

.content {
    overflow: auto;
}

div.tabs {
  width: 100%;
  margin: 0 $double-spacing;
  :global(div.ant-tabs-nav) {
    &::before {
      display: none;
    }
    margin: 0;
  }
  :global(.ant-tabs-tab) {
    // This is arbitrary - to align the static tab indicator with the height of the container
    // It would be correct to change the size of the container
    // The tabs don't use flex layouts, and changing that would make it even more fragile when updating
    padding: 7px 0;
  }
}


.anchor {
  color: $ink-color;
}

.anchorActive {
  color: $emerald;
}

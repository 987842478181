@import "../../styles/constants.scss";

.cardFooter,
.cardFooterCenter {
    display: flex;
    padding-top: 12px;
    margin-top: 50px;
    border-top: 1px solid $dark-winter;
}

.cardFooter {
    justify-content: space-between;
}

.cardFooterCenter {
    justify-content: center;
}

@import "../../styles/constants.scss";

$largeIconSize: 22px;

.largeIcon {
  width: $largeIconSize;
  height: $largeIconSize;
  fill: $emerald;
}

$smallIconSize: 13px;

.smallIcon {
  width: $smallIconSize;
  height: $smallIconSize;
  fill: $emerald;
}

.overlay {
  p {
    margin: 0px;
  }
  ul {
    margin: 0px;
    padding-left: 30px;
  }
}

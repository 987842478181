@use "sass:math";

$emerald: #41b5a9;
$emerald-light: #51d8ca;
$emerald-ultra-light: #d9f5f2;
$emerald-dark: #328680;
$black: #262626;

$clear-day: #e6f5f1;

$dark-midnight-blue: #263a44;
$midnight-blue: #2a424d;
$impero-blue: #344f5c;
$medium-blue: #4886a7;
$light-blue: #58badc;
$faded-blue: #d0e3ec;

$ink-color: #222b34;
$dark-grey: #464f5a;
$light-grey: #647380;
$faded-grey: #919eaa;

$dark-winter: #c4cdd5;
$winter: #dfe4e8;
$winter-snow: #f4f6f8;
$snow-blind: #f9fafb;
$plain-white: #ffffff;

$green: #89d65e;
$green-dark: #71b24d;
$orange: #ff7a11;
$orange-dark: #d85f1b;
$red: #e53f3f;
$red-dark: #b33434;

$extra-large-desktop-width: 1920px;
/* Match antd breakpoints (https://ant.design/components/layout/) */
$large-desktop-width: 1600px;
$medium-desktop-width: 1200px;
$small-desktop-width: 992px;
$tablet-width: 768px;
$phone-width: 576px;
$small-phone-width: 480px;

$ipad-height: 768px;

$custom-error-color: #f5222d;
$form-field-label: $dark-grey;
$read-only-font-size: 14px;

$base-radius: 8px;
$half-radius: math.div($base-radius, 2);
$icon-size: 24px;
$base-spacing: 15px;
$double-spacing: $base-spacing * 2;
$half-spacing: math.div($base-spacing, 2);

@import "../../styles/constants.scss";

:global(.ant-btn).buttonFooter {
    margin-right: 10px;
    height: 32px;
    text-align: center;
    font-size: 15px;
}

.icon {
    width: 24px;
    height: 24px;
    vertical-align: bottom;
    fill: $emerald;
}
:global(.ant-btn-primary) .icon,
:global(.ant-btn-danger) .icon {
    fill: $plain-white;
}


:global(#app) {
  height: 100vh;
  overflow: hidden;
}

:global(#body-content) {
    min-height: 100vh;
    /* Need for Dropdown/Select */
    position: relative;
}

:global(.react-phone-number-input__icon) {
  box-sizing: content-box;
  line-height: 1px;
}

:global {
  .dropdown {
    display: inline-block;
  }

  .dropdown__content {
    display: none;
    position: absolute;
  }

  .dropdown--active .dropdown__content {
    display: block;
  }
}

:global(.ant-table-wrapper) table th {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
}

:global(.borderedContainer) > :global(.ant-table-wrapper) {
    table {
        border-top: none!important;
        border-left: none!important;
        border-right: none !important;

        th:last-child,
        td:last-child {
            border-right: none!important;
        }

    }

    :global(.ant-table-placeholder) {
        border-left: none!important;
        border-right: none!important;
    }

    &:global(.noSpaceAfterLastRow):last-child {
        table {
            tr:last-child > td {
                border-bottom: none!important;
            }
        }

        :global(.ant-table-placeholder) {
            border-bottom: none!important;
        }
    }
}

@import "../../constants.module.scss";

.leftSideMenuContainer {
    height: 100%;
}

aside.leftSideMenuSider {
    box-shadow: -10px 0 8px -8px rgba(0,0,0,0.06) inset, 0 0 2px -2px rgba(0, 0, 0, 0.25) inset;
    background-color: $winter-snow;
    overflow-x: hidden;
    overflow-y: auto;
}

ul.leftSideMenu,
ul.leftSideMenu :global(.ant-menu-submenu) > :global(.ant-menu) {
    background-color: transparent;
}

.leftSideMenuToggleContainer {
    text-align: right;
    margin-top: 4px;
    margin-right: 4px;
}

.leftSideMenuToggle {
    color: $faded-grey;
}

.content {
    overflow: auto !important;
}

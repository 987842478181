@import "../../styles/constants.scss";

.container {
  margin-bottom: 10px;
  line-height: 1;
}

.label {
  text-transform: uppercase;
  color: $form-field-label;
  font-size: 12px;
  font-weight: bold;
  margin-right: 10px;
}

.showPencil {
  cursor: pointer;
}

:global(.anticon).icon {
  margin-left: 5px;
  color: $dark-winter;

  &:hover {
    cursor: pointer;
    color: $emerald;
  }
}
@use 'sass:color';
@import "../../styles/constants.scss";

.cell {
   border: 1px solid $winter;
   transition: background 0.3s;
   position: relative;
   padding: $base-spacing;
   box-sizing: border-box;
}

.cellSorted {
   background: rgba($snow-blind, 0.5);
}

.head {
    position: sticky;
}
.headCell {
    background: #F4F6F8;
}
.sortableHeadCell {
    cursor: pointer;
}
.headCellContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headCellLabel {
    position: relative;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    color: $light-grey;
}

.row {
    box-sizing: border-box;
    background: $plain-white;
}

.row:hover {
    background: $snow-blind;
}

.selected {
    background: $clear-day;
}
.selected:hover {
   background: darken($clear-day, 2%);
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 0px 0px 0 0;
    border-top: none;
    border-left: none;
    border-right: none;
}


.sorter {
   display: flex;
   flex-direction: column;
}

$caret-size: 12px;
.caret {
 color: $dark-winter!important;
 width: $caret-size;
 height: $caret-size;
}

.caretActive {
   color: $emerald!important;
}

@import "../../constants.module.scss";

.dots {
  vertical-align: middle;
  fill: $emerald;
}

.menu {
  div &:global(.ant-menu) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background: none;
    border-bottom: none;
    line-height: 40px;
  }

  :global(.ant-menu-item) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.icon {
  max-width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 8px;
}

.menuItem {
  &:hover {
    svg path {
      fill: $emerald;
    }
  }
  transition: none;
}

.disabledMenuItem {
  svg path {
    fill: $faded-grey !important;
  }
}

:global(.ant-menu-submenu-popup.ant-menu-impero-action-menu) {
  background: transparent;
}

.dropdownActionMenu {
    transition: none;
    font-size: 13px;
    width: 300px;
    padding: 0 !important;
}

.dropdownActionMenuItem {
    padding: 12px !important;
}

.dropdownActionMenuItemContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.dropdownActionMenuItemTitle {
    font-weight: bold;
}

.dropdownActionMenuItemDescription {
    white-space: normal;
    color: $light-grey;
    font-size: 11px;
    line-height: 14px;
}

.dropdownActionMenuItemDivider {
    margin: 0 !important;
    padding: 0 !important;
}

@import "../../styles/constants.scss";

:global(.ant-legacy-form-item).formItem,
.formItem {
  margin-bottom: 26px;
}

:global(.ant-form-item).formItemLite,
.formItemLite {
  margin-bottom: 0;
}

.formItemField {
  max-width: 600px;
}

.formItemFieldFullWidth {
    width: 100%;
    max-width: 100%;
}

:global(.ant-legacy-form-item-control) .formItemField, .formItemLite {
  line-height: 1;

  & a {
    font-size: $read-only-font-size;
    color: $emerald-dark;
  }
}

.hasCustomError {
  position: relative;
}

.hasCustomError::after {
  color: $custom-error-color !important;
  content: attr(data-errormessage) !important;
  position: absolute !important;
  top: 100%;
}

@import "../../styles/constants.scss";

:global(.ant-btn).disabledLinkButton {
    cursor: not-allowed;
    padding: 0;
    color: $faded-grey;

    &:hover,
    &:focus {
        color: $faded-grey;
    }
}

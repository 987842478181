.demo {
    display: flex;
}

.editor {
    flex: 1;
    margin-right: 20px;
}

.editorArea {
    width: 100%;
    height: 100%;
}

.display {
    flex: 1;
    margin-right: 20px;
}

.markdownBlock {
    border: 1px solid lightgrey;
    width: 100%;
    height: 100%;
}

.options {
    margin-right: 20px;
}

.optionLabel {
    font-weight: normal;
}

.markdown {
    word-wrap: break-word;
    word-break: break-word;
}

.markdown > *:last-child {
    margin-bottom: 0;
}

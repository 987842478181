@import "./theme.less";
@import "./fonts.less";

:global(#app) {
    height: 100vh;
    overflow: hidden;
}

:global(#body-content) {
    min-height: 100vh;
    /* Need for Dropdown/Select */
    position: relative;
}

/* Internet Explorer 11 */
@media all and (-ms-high-contrast: none) {
    /* We choose what we break: dropdown doesn't scroll in favour of no screen flickering. */
    :global(#body-content) {
        position: static;
    }
}

@media screen and (max-width: 768px) {
    :global(#body-content) {
        min-height: unset;
    }
}

:global(.react-phone-number-input__icon) {
    box-sizing: content-box;
    line-height: 1px;
}

:global {
    .dropdown {
        display: inline-block;
    }

    .dropdown__content {
        display: none;
        position: absolute;
    }

    .dropdown--active .dropdown__content {
        display: block;
    }
}

:global(.borderedContainer) > :global(.ant-table-wrapper) {
    table {
        border-top: none;
        border-left: none;
        border-right: none !important;

        th:last-child,
        td:last-child {
            border-right: none;
        }
    }

    :global(.ant-table-placeholder) {
        border-left: none;
        border-right: none;
    }

    &:last-child {
        table {
            tr:last-child > td {
                border-bottom: none;
            }
        }

        :global(.ant-table-placeholder) {
            border-bottom: none;
        }
    }
}

@body-background: #dfe4e8;@layout-body-background: #dfe4e8;@font-family: "Assistant", sans-serif !important;@primary-color: #41B5A9;@link-color: #41B5A9;@success-color: #87D64F;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: #222B34;@text-color: #222B34;@text-color-secondary: rgba(34,43,52,0.5);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #C4CDD5;@border-color-split: #DFE4E8;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@text-selection-bg: #D9F5F2;@text-color-inverse: #222B34;@table-border-radius-base: 0px;@table-header-color: #647380;@table-header-bg: #F4F6F8;
@import "../../constants.module.scss";

.rightSideIconContainer {
  background-image: url("img/bgright.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 40%;
  display: flex;
}

.rightSideIcon {
  width: 203px;
  height: 203px;
  margin: auto;
  fill: white;
}

@media screen and (max-width: $medium-desktop-width) {
  .rightSideIconContainer {
    display: none;
  }
}

@import "../constants.module.scss";

.siderMenuIcon {
  stroke: #fff;
  width: 50px;
  height: 48px;
  margin: -15px auto;
}

.menuIcon {
  fill: #fff;
  width: 32px;
  height: 32px;
  vertical-align: middle;
}

.contentContainer {
  height: 100%;
  overflow: auto;
}

@media (max-width: 767px) {
  .contentContainer {
    margin-top: 45px;
  }
}

@media screen and (max-height: $ipad-height - 1px) {
  :global {
    .ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
      overflow-y: hidden;
    }
  }
  .contentContainer {
    overflow: hidden;
  }
}

/* CUSTOM LEFT MENU CSS */

.leftMenuContainer {
    height: 100%;
}

.leftMenuSider {
    overflow-x: hidden;
    overflow-y: auto;
}

// Additional class selector added for higher specificity
.leftMenuContainer .leftMenuSider,
.leftMenuContainer .leftMenuSider .leftMenu {
  text-align: left;
  background: $midnight-blue;
  line-height: 1.0;
}

// Additional class selector added for higher specificity
.leftMenu :global(.ant-menu-dark, .ant-menu-dark .ant-menu-sub) {
  background: $midnight-blue;
}

// Additional class selector added for higher specificity
.leftMenuContainer .leftMenu li {
  padding: 0 0 0 20px !important;
}

// Additional class selector added for higher specificity
.leftMenuContainer .leftMenuSider .leftMenu li > div {
  padding: 0px !important;
}

// Additional class selector added for higher specificity
.leftMenuContainer :global(.ant-menu.ant-menu-dark .ant-menu-item-selected) {
  background-color: transparent !important;
}

// Additional class selector added for higher specificity
.leftMenuContainer .leftMenuSider :global(.ant-menu-vertical .ant-menu-item),
.leftMenuContainer .leftMenuSider :global(.ant-menu-inline .ant-menu-item),
.leftMenuContainer .leftMenuSider :global(.ant-menu-vertical .ant-menu-submenu),
.leftMenuContainer .leftMenuSider :global(.ant-menu-inline .ant-menu-submenu) {
  padding-top: 9px !important;
  padding-bottom: 11px !important;
}

.leftMenuTopIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70px;
  padding: 40px 0 35px;
  cursor: pointer;
}

.MenuButtonText {
  padding-top: 5px;
  color: #fff;
  font-size: 11px;
  text-align: center;
}

.LeftMenuItem {
  margin-bottom: 6px;
  /* Fix SVG interpolation */
  line-height: 1.0 !important;
  height: auto !important;

  &:hover {
    background-color: $dark-midnight-blue !important;
    * {
      fill: $emerald;
      color: $emerald;
    }
  }
  transition: none;
}

.LeftMenuItem :global(.anticon) {
  /* Fix SVG interpolation */
  line-height: 1.0 !important;
}

.LeftMenuItemText {
  color: #fff;
  font-size: 18px;
  padding-left: 22px;
  font-family: "Assistant", sans-serif !important;
  vertical-align: -0.1em;
}

:global(.ant-menu-inline-collapsed) {
  width: 70px !important;
}

:global(.ant-tooltip-placement-right) {
  display: none !important;
}

.RiskDirLink {
  display: block;
  color: #262626;
}

.RiskDirLink:hover {
  text-decoration: underline;
  color: #262626;
}

@media screen and (max-width: $tablet-width) {
  .MenuButtonText {
    display: none;
  }

  .leftMenuTopIcon {
    padding: 14px 0 48px;
  }
}

@media only all and (min-height: $ipad-height) {
  .leftMenuContainer {
    height: 100vh;
  }
}

/* CUSTOM LEFT MENU CSS END */

.menuTop {
  background-color: #334d5c;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 45px;
  z-index: 100;
}

.menuTopButton {
  padding-top: 14px;
  margin-left: 10px;
  width: 30px;
  cursor: pointer;
}

.menuTopTitle {
  color: white;
  position: absolute;
  top: 9px;
  left: 35%;
  font-family: "Assistant", sans-serif !important;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  max-width: 180px;
}

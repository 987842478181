@import "../../styles/mixins.scss";

.tagName {
    font-weight: bold;
    font-size: 10px;
}

.tagGroup {
    overflow: hidden;
    text-overflow: ellipsis;
}

.categoryName {
    font-size: 10px;
}

.bubbleTagContainer {
    border: 1px solid $lightGrey; // before: composes: border;
    font-size: 12px;
    border-radius: 20px;
    padding: 5px 10px;
    margin-right: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
    background-color: #fff;
    display: block;
}

.tagContainer {
    display: flex;
    flex-wrap: wrap;
}

.bubbleTagLabel {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    max-width: 200px;
    min-width: 0; /* Needed for white-space: nowrap to work */
}

.bubbleTagDelete {
    display: inline-block;
    padding-left: 10px;
}

.removeIcon {
    vertical-align: middle;
    width: 18px;
    height: 18px;
}

.bubbleTagDelete img {
    height: 10px;
    opacity: 0.5;
}

.bubbleCategoryName {
    min-width: 20px;
}

.bubbleTagName {
    margin-left: 5px;
    font-weight: bold;
}

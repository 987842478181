@import "../../styles/constants.scss";
@import "../../styles/legacyConstants.scss";

$preselectedBackgroundColor: #428bca;
$preselectedColor: #fff;

.complexDropdown :global(.dropdown__trigger) {
    display: inline-block;
    border: 1px solid $lightGrey;
    background-color: #fff;
    color: $black;
    text-decoration: none;
    border-radius: 2px;
}

.fullWidthComplexDropdown {
    composes: complexDropdown;
    width: 100%;
}

.fullWidthComplexDropdown :global(.dropdown__trigger) {
    width: 100%;
}

.complexDropdown.withSuggestions:global(.dropdown--active)
    :global(.dropdown__trigger) {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom: 2px solid $lightGrey;
}

.complexDropdown :global(.dropdown__trigger):hover,
.complexDropdown :global(.dropdown__trigger):focus {
    color: $black;
    text-decoration: none;
}

$arrowMargin: 10px;
$arrowHalfWidth: 5px;

.complexArrowContainer {
    width: $arrowMargin + 2 * $arrowHalfWidth;
}

.baseArrow {
    margin-left: $arrowMargin;
}

.complexArrow {
    composes: baseArrow;
}

.baseComplexTriggerInner {
    margin: 8px;
    min-width: 170px;
    height: 18px;
}

.complexTriggerInner {
    composes: baseComplexTriggerInner;
    display: inline-flex;
}

.fullWidthComplexTriggerInner {
    composes: baseComplexTriggerInner;
    display: flex;
}

.complexTriggerLabel {
    flex: auto;
    font-size: 13px;
}

:global(.dropdown--active) .triggerWithFilter {
    display: none;
}

.triggerInput {
    display: none;
    margin: 4px;
    height: 25px;
}

.triggerInput input {
    box-sizing: content-box;
    border: none;
    outline: none;
    margin: 2px 4px;
    min-width: 170px;
    height: 24px;
    box-shadow: none;
    background-color: white;
}

:global(.dropdown--active) {
    position: relative;
}

:global(.dropdown--active) .triggerInput {
    display: block;
}

.complexDropdown :global(.dropdown__content) {
    position: absolute;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-top: 0;
    left: 0;
    right: auto;
    border-radius: 0px 0px 2px 2px;
    white-space: nowrap;
    z-index: 1000;
    cursor: default;
}

.complexDropdown :global(.dropdown__content) {
    width: 100%;
}

.complexDropdown.noSuggestions :global(.dropdown__content) {
    border: 0;
}

.complexDropdown :global(.dropdown__content) .scroller {
    max-height: 260px;
    overflow-x: hidden;
    overflow-y: auto;
}

.complexDropdown :global(.dropdown__content) ul {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}

.complexOptionLabel {
    text-overflow: ellipsis;
    overflow: hidden; /* for ellipsis to work */
    cursor: pointer;
    padding: 8px 10px;
}

.optionLabel.preselectedOption {
    color: $preselectedColor;
    background-color: $preselectedBackgroundColor;
}

.complexDropdown
    :global(.dropdown__content)
    :global(.depth_1)
    > li
    > .optionLabel {
    color: $lightGrey;
    font-size: 12px;
}

.complexDropdown :global(.dropdown__content) :global(.depth_1) > hr {
    margin: 6px 10px 6px 10px;
    border-top: 1px solid $lightGrey;
}

.complexDropdown
    :global(.dropdown__content)
    :global(.depth_2)
    > li
    > .optionLabel {
    padding: 6px 10px;
    font-size: 13px;
}

.complexDropdown :global(.dropdown__content) hr {
    margin: 0;
    border: none;
}

li.selectedOption > .optionLabel {
    background-color: $lightGrey;
}

li.selectedOption > .optionLabel.preselectedOption {
    color: $preselectedColor;
    background-color: $preselectedBackgroundColor;
}

:global(.depth_1) > li.selectedOption > .optionLabel {
    background: none;
}

:global(.depth_1) > li.selectedOption > .optionLabel.preselectedOption {
    color: $preselectedColor;
    background-color: $preselectedBackgroundColor;
}

@import "../../constants.module.scss";

.formWrapper,
.formWrapperFullWidth {
  background: #dfe4e8;
  min-height: $ipad-height;
  height: 100vh;
  display: flex;
}

.formWrapper {
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.formWrapperFullWidth {
  align-items: center;
}

.cardContent {
    overflow: visible;
    grid-area: card;
    justify-self: center;
    align-self: center;
}

.cardLogo {
    text-align: center;
}

.loginFormLeft,
.loginFormLeftFullWidth {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(3, auto);
  grid-template-areas:
      ". . ."
      ". card ."
      ". footer .";
}

.loginFormLeft {
  width: 60%;
}

.loginFormLeftFullWidth {
  width: 100%;
  justify-content: center;
}

.loginCardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loginFormCard {
  width: 504px;
  padding: 0 34px !important;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  overflow: hidden;
}

.textFooter {
  text-align: center;
  margin-bottom: 0px;
}

.formPageFooter {
  width: inherit;
  grid-area: footer;
  justify-self: center;
  align-self: end;
  padding-bottom: 10px;
}

.formPageFooterSvg {
  display: block;
  margin: 0 auto 4px;
}

@media screen and (max-width: $medium-desktop-width) {
  .loginFormLeft,
  .loginFormLeftFullWidth {
    width: 100%;
  }
}

@media screen and (max-width: $phone-width) {
  .loginFormCard {
    width: auto;
    margin: 40px 12px 0 !important;
    padding: 0px !important;
  }
}
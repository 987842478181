@import "../../styles/constants.scss";
@import "../../styles/legacyConstants.scss";

.icon {
    width: 50px;
    height: 50px;
    opacity: 0.5;
}

.card {
    text-align: center;
}

.content {
    color: $faded-grey;
}

@import "../../styles/constants.scss";

$arrowHalfWidth: 5px;

.arrow {
    display: inline-block;
    width: 0;
    height: 0;
    margin-top: auto;
    margin-bottom: auto;
    vertical-align: middle;
    border-top: 5px solid $black;
    border-right: $arrowHalfWidth solid transparent;
    border-bottom: 0 dotted;
    border-left: $arrowHalfWidth solid transparent;
    content: "";
    padding-bottom: 4px;
}

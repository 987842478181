@import "../../constants.module.scss";

.formCard {
  padding: 24px 20px 0 !important;
}

.formCard input {
  text-align: left !important;
}

@media screen and (max-width: $phone-width) {
  .formCard {
    padding: 0px !important;
  }
}

@media screen and (max-height: $ipad-height) {
  .formCard {
    padding: 0 20px !important;
  }
}

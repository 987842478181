@import "../../constants.module.scss";

/* Total size of the element */
$fullHeight: 40px;
/* Height of the border at the bottom, which is transparent per default and green for selected elements */
$borderBottomHeight: 2px;

/* Height of the tab label */
$tabLabelHeight: $fullHeight - $borderBottomHeight;

.menu {
    width: 100%;
    :global(.ant-menu) {
        /* Don't set the background in this generic component because we want to be able to override
         it in the application bar which sets a rule with the same CSS specificity, meaning the
         precedence is erratic. */
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        color: $faded-grey;
        line-height: $tabLabelHeight!important;
        height: $tabLabelHeight;
        max-height: $tabLabelHeight;
        border-bottom: $borderBottomHeight solid transparent;
    }

}

.menuItem {
  transition: none;

  :global(.ant-badge) {
    color: unset;
  }
}
